@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

body {
  overflow-x: hidden
}

.react-international-phone-input-container {
  background: #f4f4f5 !important;
  border-radius: 8px;
  padding: 8px;
}
.react-international-phone-country-selector {
  background: #f4f4f5 !important;
  border: none !important;
}
.react-international-phone-country-selector-button{
  background: #f4f4f5 !important;
  border: none !important;
}
.react-international-phone-input{
  background: #f4f4f5 !important;
  border: none !important;
}
.react-international-phone-country-selector-dropdown{
  padding: 12px !important;
  border-radius: 8px !important;
}
.react-international-phone-country-selector-dropdown__list-item {
  padding: 4px !important;
  border-radius: 8px !important;
}
/* End custom react-international-phone */
html {
  font-family: var(--font-dm_sans);
  scroll-behavior: smooth;
}

.font-degular-display-bold {
  font-family: var(--font-degular-display-bold) !important;
}

.font-degular-display-semibold {
  font-family: var(--font-degular-display-semibold) !important;
}

.font-degular-display-black-italic {
  font-family: var(--font-degular-display-black-italic) !important;
}

.font-degular-display-bold-italic {
  font-family: var(--font-degular-display-bold-italic) !important;
}

.font-degular-display-medium {
  font-family: var(--font-degular-display-medium) !important;
}

.font-degular-display-bold-italic {
  font-family: var(--font-degular-display-bold-italic) !important;
}

.font-degular-display-regular {
  font-family: var(--font-degular-display-regular) !important;
}
.font-dm_sans {
  font-family: var(--font-dm_sans), sans-serif !important;
}

.zstack {
  display: grid;
  align-items: center;
  justify-items: center;
}
.zstack > * {
  grid-area: 1/1/1/1;
}

.pac-container {
  @apply font-dm_sans
}

.h1 {
  @apply font-degular-display-semibold !text-[40px] md:!text-[64px] !leading-none;
}

.h2 {
  @apply font-degular-display-semibold md:!text-[56px] !leading-none !text-[40px];
}

.h3 {
  @apply font-degular-display-semibold !text-[48px] !leading-none;
}

.h4 {
  @apply font-degular-display-semibold !text-[32px] !leading-none;
}

.h5 {
  @apply font-degular-display-medium text-[20px] md:!text-[24px] !leading-[1.2];
}

.body {
  @apply font-dm_sans text-[16px] !leading-[1.2] !tracking-[-3%];
}

.nav {
  @apply font-degular-display-semibold !text-[16px] !leading-[24px];
}

.nav-sub {
  @apply font-dm_sans text-[14px] !leading-[1.5];
}

.gradient-mask-b-to-t::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  mask-image: linear-gradient(to top, rgba(0, 0, 0, 0.6) 0%, transparent 60%);
}

button {
  outline: none !important;
}

.header__navsub-animation {
  animation: header__navsub-animation ease-out 2s infinite;
}

@keyframes header__navsub-animation {
  0% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateX(0);
  }
}

.leading-trim-both {
  leading-trim: both;
}

.guidelines_list {
  @apply space-y-[18px];
}
.guidelines_list-item {
  @apply before:inline-block
        before:bg-[url('/assets/images/quarter-circle.svg')] before:bg-no-repeat
        before:mr-[32px] before:w-[12px] before:h-[12px] before:bg-cover
        text-[18px] tracking-[-0.54px] leading-trim-both;
}

.guidelines_list-item::before {
  content: "";
}

.guidelines_child_list {
  @apply space-y-[18px] ml-[44px] my-[26px];
}

.guidelines_child_list-item {
  @apply before:inline-block
        before:bg-[url('/assets/images/quarter-circle-outline.svg')] before:bg-no-repeat
        before:mr-[32px] before:w-[12px] before:h-[12px] before:bg-cover
        text-[18px] tracking-[-0.54px] leading-trim-both;
}

.text_content {
  font-family: var(--font-dm_sans) !important;
  letter-spacing: -0.54px !important;
  @apply text-[18px] leading-[1.5];
}

.text_content img {
  @apply !rounded-[40px] bg-[#D9D9D9] mt-[12px];
}

.text_content h2 {
  @apply !mb-[24px] !mt-[48px] !leading-none;
}

.text_content h2, .text_content h2 > * {
  font-family: var(--font-degular-display-medium) !important;
  @apply !text-[32px];
}

.text_content h3 {
  /* @apply !mb-[16px] !mt-[24px] !leading-none; */
}

.text_content h3,
.text_content h3 > *,
.text_content h4,
.text_content h4 > * {
  font-family: var(--font-degular-display-medium) !important;
  @apply !text-[24px];
}

.text_content a > *,
.text_content a {
  @apply !text-secondary-orange;
}

.text_content figcaption {
  @apply !text-[16px] text-right leading-[1.2] tracking-[-3%] mt-[12px];
}

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.text_content blockquote {
  @apply border-l-[6px] border-[#D9D9D9] pl-[20px];
}

.text_content ul {
  @apply !list-disc pl-[20px];
}

.text-shadow {
  text-shadow:
    #7D7D7D 1px 1px,
    #7D7D7D 0px 0px,
    #7D7D7D 1px 1px,
    #7D7D7D 2px 2px,
    #7D7D7D 3px 3px,
    #7D7D7D 4px 4px,
    #7D7D7D 5px 5px,
    #7D7D7D 6px 6px,
    #7D7D7D 7px 7px,
    #7D7D7D 8px 8px,
    #7D7D7D 9px 9px,
    #7D7D7D 10px 10px;
}

.text_content table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1rem;
  font-family: Arial, sans-serif;
}

.text_content table th,
.text_content table td {
  padding: 0.75rem;
  text-align: left;
  border: 1px solid #e2e8f0;
}

.text_content table th {
  background-color: #f8fafc;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.875rem;
  color: #4a5568;
}

.text_content table tr:nth-child(even) {
  background-color: #f9fafb;
}

.text_content table tr:hover {
  background-color: #edf2f7;
}

@media (max-width: 640px) {
  .text_content table {
    font-size: 0.875rem;
  }

  .text_content table th,
  .text_content table td {
    padding: 0.5rem;
  }

  .text_content table th {
    font-size: 0.75rem;
  }
}

@media (max-width: 480px) {
  .text_content table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
}